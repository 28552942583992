<template>
    <div class="content">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <ValidationObserver
                v-slot="{ handleSubmit, invalid, touched }"
                ref="createForm"
              >
                <form
                  @submit.prevent="handleSubmit(generateTitles)"
                >
                  <div class="row">
                    <div class="col-12 col-sm-6">
                      <TextInput
                          v-model="projectName"
                          rules="required"
                          label="Project Name"
                          class="pr-2 mb-0"
                          marginBottom="0"
                          name="Name"
                      />
                    </div>
                    <div class="col-12 col-sm-6">
                      <label>Content Type</label>
                      <b-form-select @change="startOver" v-model="type" :options="options" rules="required"></b-form-select>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-12 col-sm-6">
                      <div class="mb-3">
                        <label>Writing Tone</label>
                        <v-select placeholder="You may choose from the list or enter your own" v-model="selected_tone" :options="tones" rules="required" taggable="true" selectOnTab="true"></v-select>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6">
                      <div class="mb-3">
                        <label>Writing Style</label>
                        <v-select placeholder="You may choose from the list or enter your own" v-model="selected_style" :options="styles" rules="required" taggable="true" selectOnTab="true"></v-select>
                      </div>
                    </div>
                    <div v-if="type > 0" class="col-12 col-sm-6">
                      <TextInput
                          v-model="topic"
                          rules="required"
                          label="Topic or Keyword"
                          class="pr-2"
                          marginBottom="0px"
                          name="Topic"
                          :disabled="subTitles.length !== 0"
                      />
                    </div>
                    <div v-if="type > 0" class="col-12 col-sm-6">
                      <TextInput
                          v-model="word_count"
                          rules="required"
                          :label="type===3 ? 'Approx. Word Count Per Chapter':'Approx. Word Count'"
                          type="number"
                          class="pr-2"
                          marginBottom="0px"
                          name="Count"
                      />
                    </div>
                  </div>
                  <div v-if="type === 0" class="row mb-4">
                    <div class="col">
                      <label class="form-label">Prompt</label>
                      <textarea v-model="freeform_prompt" class="form-control">

                    </textarea>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <label>API Model</label>
                      <b-form-select v-model="selected_model" @change="verifyGPT4" :options="model_options" rules="required"></b-form-select>
                    </div>
                  </div>
                  <b-form-group v-if="(type === 3 && ebook_step < 2 || type !== 3 && !subTitles.length) && type !== 0" class="create-button">
                      <b-button
                          variant="success"
                          class="btn-dark-green"
                          type="submit"
                          :disabled="loadingCreate || (invalid && touched)"
                      >
                        <b-spinner v-if="loadingCreate" small />
                        <span  v-else>Generate Titles</span>
                      </b-button>
                  </b-form-group>
                </form>
              </ValidationObserver>
                <div v-if="type !== 0">
                  <div v-if="titles.length > 0 || subTitles.length">
                    <div v-if="!hideTitleList">
                      <label>Choose one title to generate subheadings</label>
                      <div v-for="title of titles" :key="title.id" class="d-flex subtitle-box">
                        <input :checked="title.title === activeTitle" class="checkbox" type="radio" name="titleCheckbox" @input="event  => setTitle(event, title)"/>
                        <b-form-textarea
                            v-model="title.title"
                            label="Title"
                            class="pr-2 ml-2 w-100"
                            marginBottom="0px"
                            @keyup="activeTitle = title.title"
                        />
                        <span class="refresh-icon">
                          <b-spinner v-if="titleUpdating === title" small/>
                          <feather v-else type="rotate-ccw" class="icon-xs" @click="refreshTitle(title)"></feather>
                        </span>
                      </div>
                    </div>
                    <div v-else class="dropbox">
                      <TextInput
                          :value="activeTitle"
                          label="Title"
                          class="pr-2"
                          marginBottom="0px"
                          name="Topic"
                          disabled
                      />
                    </div>
                  </div>
                  <div v-if="type === 3">
                    <div v-if="titles.length > 0 || final_content">
                      <div v-if="activeTitle && ebook_step > 1 || final_content">
                        <TextInput
                            v-model="num_of_subtitles"
                            label="How Many Chapters"
                            class="pr-2"
                            marginBottom="0px"
                            name="chapter_count"
                            :disabled="ebook_step > 2"
                        />
                        <div v-if="ebook_step > 2 || final_content">
                          <label>Add an Introduction</label>
                          <b-form-select v-model="ebook_intro" :options="ebook_intro_options"></b-form-select>
                        </div>
                        <div v-if="ebook_step > 3 || final_content" class="mt-3">
                          <label>Add a Conclusion</label>
                          <b-form-select v-model="ebook_conclusion" :options="ebook_conclusion_options"></b-form-select>
                        </div>
                        <div v-if="subTitles.length && ebook_step > 4 || final_content" class="mt-3">
                          <label>Choose chapter titles to generate content</label>
                          <draggable v-model="subTitles" handle=".handle">
                            <div v-for="subTitle of subTitles" :key="subTitle.id" class="d-flex subtitle-box">
                              <input v-model="subTitle.include" :checked="subTitle.include" class="checkbox" type="checkbox"/>
                              <feather type="move" style="width: 20px;" class="ml-2 handle icon-xs"></feather>
                              <b-form-textarea
                                  v-model="subTitle.title"
                                  class="pr-2 ml-2 w-100"
                              />
                              <span class="refresh-icon">
                            <b-spinner v-if="updateId === subTitle" small/>
                            <feather v-else type="rotate-ccw" class="icon-xs" @click="refreshSubtitle(subTitle)"></feather>
                          </span>
                            </div>
                          </draggable>
                        </div>
                      </div>
                      <b-form-group v-if="!subTitles.length" class="create-button">
                        <b-button
                            variant="success"
                            class="btn-dark-green mt-3"
                            type="submit"
                            @click="eBookNextStep"
                        ><b-spinner v-if="loadingSubCreate" small />
                          <span v-else>Next</span></b-button>
                      </b-form-group>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="titles.length > 0 && !subTitles.length" class="dropbox">
                      <b-form-group class="create-button">
                        <b-button
                            variant="success"
                            class="btn-dark-green"
                            type="submit"
                            :disabled="loadingSubCreate"
                            @click="generateSubTitles"
                        >
                          <b-spinner v-if="loadingSubCreate" small />
                          <span v-else>Generate Subheadings</span>
                        </b-button>
                      </b-form-group>
                    </div>
                    <div v-if="subTitles.length">
                      <label>Choose subheadings to generate content</label>
                      <draggable v-model="subTitles" handle=".handle">
                        <div v-for="subTitle of subTitles" :key="subTitle.id" class="d-flex subtitle-box">
                          <input v-model="subTitle.include" :checked="subTitle.include" class="checkbox" type="checkbox"/>
                          <feather type="move" style="width: 20px;" class="ml-2 handle icon-xs"></feather>
                          <b-form-textarea
                              v-model="subTitle.title"
                              class="pr-2 ml-2 w-100"
                          />
                          <span class="refresh-icon">
                            <b-spinner v-if="updateId === subTitle" small/>
                            <feather v-else type="rotate-ccw" class="icon-xs" @click="refreshSubtitle(subTitle)"></feather>
                          </span>
                        </div>
                      </draggable>
                    </div>
                  </div>
                </div>
              <div v-if="subTitles.length || type === 0">
                <b-form-group class="create-button mt-16">
                  <b-button
                      variant="primary"
                      class="btn-dark-blue start-over-button"
                      type="button"
                      @click="showStartOverModal=true"
                  >
                    <span>Start over</span>
                  </b-button>
                  <b-button
                      variant="success"
                      class="btn-dark-green"
                      type="button"
                      :disabled="loadingContent"
                      @click="generateContent"
                  >
                    <b-spinner v-if="loadingContent" small />
                    <span  v-else>Generate Content</span>
                  </b-button>
                  <b-button
                      class="ml-4"
                      v-if="showSaveButton"
                      variant="info"
                      type="button"
                      @click="saveContent"
                  >
                    <b-spinner v-if="savingContent" small />
                    <span  v-else>Save</span>
                  </b-button>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12" v-if="final_content || loadingContent">
            <div class="card">
              <div class="card-body" style="min-height: 85vh">
                <div class="row">
                    <div v-if="loadingContent" style="text-align: center; width: 100%; margin-top: 240px">
                      <b-spinner></b-spinner>
                      <p class="loading-text">AI is writing your text</p>
                    </div>
                    <div v-else class="content-region p-3">
                        <b-form-group class="create-button">
                          <b-button variant="info"
                                    class="mr-2"
                                    type="button" @click="reWriteContent">Re-write selected Text</b-button>
                            <b-button
                                variant="success"
                                class="btn-dark-green"
                                type="button"
                                @click="copyContent"
                            >
                            <span>Copy to clipboard</span>
                            </b-button>
                        </b-form-group>
                        <div class="w-100" ref="finalContent">
                          <vue2-tinymce-editor @editorInit="setTEOptions" v-model="final_content" :height="800" ref="te"></vue2-tinymce-editor>
                        </div>
                    </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <b-modal
        v-model="progress"
        title="Confirm"
        title-class="font-18"
      >
        <h5>Sorry, It's still in process.</h5>
        <template v-slot:modal-footer>
          <b-button variant="light" @click="progress = false"
            >Close</b-button
          >
        </template>
      </b-modal>
      <b-modal
        v-model="showStartOverModal"
        title="Confirm"
        title-class="font-18"
      >
        <h5>Are you sure you want to start over and clear everything out?</h5>
        <template v-slot:modal-footer>
          <b-button variant="light" @click="startOver"
            >Yes</b-button
          >
          <b-button variant="light" @click="showStartOverModal = false"
            >No</b-button
          >
        </template>
      </b-modal>
      <b-modal :no-close-on-backdrop="true" :no-close-on-esc="true" v-model="showRewriteModal" @close="resetRewrite">
        <label>Old Text</label>
        <div v-html="old_text"></div>
        <br />
        <label>New Text</label>
        <div>
          <span v-if="new_text" v-html="new_text"></span>
          <b-spinner v-else small />
        </div>
        <template v-slot:modal-footer>
          <b-button :disabled="rewrite_working" variant="danger" @click="old_text = null"
          >Close</b-button
          >
          <b-button :disabled="rewrite_working" variant="info" @click="reWriteContent"
          >Rewrite again</b-button
          >
          <b-button :disabled="rewrite_working" variant="success" @click="acceptReWrittenContent"
          >Accept</b-button
          >
        </template>
      </b-modal>
      <b-modal v-model="showNoAPIModal" title="Using GPT-4">
        You need to add an API key to your account that is approved for GPT-4. You may join the wait-list here - <a target="_blank" href="https://openai.com/waitlist/gpt-4-api">https://openai.com/waitlist/gpt-4-api</a>
        <br />
        <br />
        If you already have an API key, add it to the <router-link :to="{ name: 'agency.settings' }">Settings Page</router-link> to start using GPT-4
        <template v-slot:modal-footer>
          <b-button variant="light" @click="showNoAPIModal = false"
          >Close</b-button
          >
        </template>
      </b-modal>
    </div>
  </template>

  <script>
  import Vue from "vue";
  import draggable from 'vuedraggable'
  import { Vue2TinymceEditor } from "vue2-tinymce-editor";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";
  export default {
    components: {
      draggable,
      Vue2TinymceEditor,
      "v-select": vSelect
    },
    data() {
      return {
        final_content:null,
        content_id:null,
        loadingCreate: false,
        options: [
          { value: 0, text: 'Free-form' },
          { value: 1, text: 'Blog Post' },
          { value: 2, text: 'Website Copy' },
          { value: 3, text: 'E-Book' }
        ],
        teoptions:{
          lineheight_formats: '1.5 1.6 1.7 1.8 1.9 2'
        },
        num_of_subtitles:3,
        word_count:500,
        type: 1,
        subCheck: null,
        subtitle: null,
        topic: null,
        projectName: null, //
        subData: null,
        loadingContent: false,
        updateId: null,
        titleUpdating: null,
        progress: false,
        title:null,
        //titles: [],
        subTitles:[],
        loadingSubCreate: false,
        activeTitle: null,
        showSubForm: false,
        showStartOverModal: false,
        showSaveButton: false,
        savingContent: false,
        selected_tone:'',
        composition_type:'Combined',
        tones:["Default", 'Informative', 'Persuasive', 'Conversational', 'Formal', 'Humorous', 'Emotional', 'Storytelling', 'Technical', 'Descriptive', 'Poetic'],
        styles:["Default","Academic","Analytical","Argumentative","Conversational","Creative","Critical","Descriptive","Epigrammatic","Epistolary","Expository","Informative","Instructive","Journalistic","Metaphorical","Narrative","Persuasive","Poetic","Satirical","Technical"],
        selected_style:"",
        freeform_prompt:null,
        completelyLoaded:false,
        contentChanged: false,
        new_text:null,
        old_text:null,
        rewrite_working:false,
        ebook_step:1,
        ebook_working:false,
        ebook_intro_options: [
          { value: 0, text: 'No' },
          { value: 1, text: 'Yes' }
        ],
        ebook_conclusion_options: [
          { value: 0, text: 'No' },
          { value: 1, text: 'Yes' }
        ],
        model_options:[
          {
            value: 'gpt-3.5-turbo',
            text: 'GPT-3.5'
          },
          {
            value: 'gpt-4',
            text: 'GPT-4'
          }/* ,
          {
            value: 'gpt-4-32k',
            text: 'GPT-4 32K'
          } */
        ],
        selected_model:'gpt-3.5-turbo',
        ebook_intro:0,
        ebook_conclusion:0,
        showNoAPIModal:false
      }
    },
    watch:{
      'final_content': function(val,oldVal){
        if(this.completelyLoaded){
          if(!this.content_id || this.content_id && oldVal !== null) {
            this.contentChanged = true;
          }
        }
      },
     /*  'selected_model': function(val, oldVal){
        if(val !== 'gpt-3.5-turbo' && !this.user.api_key){
          alert('sorry, but you need to have an API key added to your account that is approved for GPT-4.')
          this.selected_model = 'gpt-3.5-turbo';
          this.$root.$nextTick();
        }
        console.log({
          val:val,
          user:this.user,
          oldVal:oldVal
        })
      } */
    },
    computed: {
      titles() {
        return this.$store.getters['content/titles']
      },
      content() {
        return this.$store.getters['content/content']
      },
      hideTitleList(){
        return this.subTitles.length && this.activeTitle || (this.activeTitle && this.type ===3 && this.ebook_step > 1);
      },
      user() {
        return this.$store.getters['auth/user']
      },
      showRewriteModal(){
        return this.old_text !== null;
      }
    },

    created() {
      //Reset content
      this.startOver();
      this.content_id = this.$route.params.id;
      this.getData();

      if(!this.content_id && false) {
        //Testing
        this.projectName = 'Test';
        this.type = 1;
        this.topic = "Teeth Whitening";
      }
    },

    mounted() {
      window.onbeforeunload = function() {
          return "Are you sure you want to leave this page?";
      };
    },
    beforeRouteLeave (to, from , next) {
      if(!this.savingContent && this.contentChanged) {
        const answer = window.confirm('Are you sure you want to leave this page? You appear to have unsaved changes.')
        if (answer) {
          next()
        } else {
          next(false)
        }
      }else{
        next();
      }
    },
    methods: {
      verifyGPT4(option){
        if(this.selected_model !== 'gpt-3.5-turbo' && !this.user.api_key){
          this.showNoAPIModal = true;
          this.selected_model = 'gpt-3.5-turbo';
        }
      },
      startOver() {
        this.showStartOverModal = false;
        this.topic = null;
        this.title = null;
        this.final_content = null;
        this.activeTitle = null;
        this.$store.state.content.create.subtitles = [];
        this.$store.state.content.create.titles = [];
        this.subTitles = [];
        this.showSaveButton = false;
        this.freeform_prompt = null;
        this.projectName = null;
        this.new_text = null;
        this.old_text = null;
        this.rewrite_working = false;
        this.ebook_step = 1;
        this.ebook_working = false;
        this.ebook_intro = 0;
        this.ebook_conclusion = 0;
        this.num_of_subtitles = 3;
        this.word_count = 500;
      },
      setTitle(e, title) {
        if(e.target.checked) {
          this.activeTitle = title.title;
        }
      },
      generateSubTitles() {
        if(!this.activeTitle) {
          Vue.$toast.open({
            message: 'Please select a title.',
            type: 'error',
            duration: 5000,
            position: 'bottom'
          });
          return false;
        }
        this.loadingSubCreate = true;
        this.$store
          .dispatch('content/generateSubtitles', {title: this.activeTitle, type: this.type, num_of_subtitles:this.num_of_subtitles, tone: this.selected_tone, model:this.selected_model})
          .then((res) => {
            if(res.success) {
              this.subTitles = res.data;
              if (this.subTitles.length && this.type === 3) {
                this.ebook_step++;
              }
              this.loadingSubCreate = false;
            }else{
              this.loadingSubCreate = false;
              Vue.$toast.open({
                message: res.message,
                type: 'error',
                duration: 5000,
                position: 'bottom'
              })
            }
          })
          .catch((err) => {
              if (err.response.data.errors) {
                this.$refs.createForm.setErrors(err.response.data.errors)
              }
              this.loadingSubCreate = false
          })
      },
      pageText(text) {
        let tex = text.replaceAll('\n', '<br/>');
        return {innerHTML: tex};
      },
      listener(e) {
        e.clipboardData.setData("text/html", this.$refs.finalContent.innerHTML);
        //e.clipboardData.setData("text/plain", this.$refs.finalContent.innerHTML);
        e.preventDefault();
      },
      copyDocumentContent() {
        document.addEventListener("copy", this.listener);
        document.execCommand("copy");
        document.removeEventListener("copy", this.listener);
        Vue.$toast.open({
          message: 'Copied',
          type: 'success',
          duration: 5000,
          position: 'bottom'
        })
      },
      eBookNextStep(){
        switch(this.ebook_step){
          case 1:
            //Commit Title
            if(this.activeTitle){
              this.ebook_step++;
            }
            break;
          case 2:
            //Define Chapters
            this.ebook_step++;
            break;
          case 3:
            //Include Intro
            this.ebook_step++;
            break;
          case 4:
            //Include Conclusion
            this.generateSubTitles();
            break;
          case 5:
            this.loadingCreate = true;
            //Generate Chapter Titles
        }
      },
      acceptReWrittenContent(){
        this.final_content = this.final_content.replace(this.old_text,this.new_text);
        this.new_text = null;
        this.old_text = null;
      },
      resetRewrite(){
        this.rewrite_working = false;
        this.new_text = null;
        this.old_text = null;
      },
      reWriteContent(){
        this.rewrite_working = true;
        this.new_text = null;
        this.old_text = null;
        let editor = this.$refs.te.editor;
        if(editor.selection.isCollapsed()){
          Vue.$toast.open({
            message: 'Please select text first',
            type: 'error',
            duration: 5000,
            position: 'bottom'
          });
        }else{
          this.old_text = editor.selection.getContent();
          this.$store
              .dispatch('content/rewriteContent', {article:this.final_content, selected_text: editor.selection.getContent(), model:this.selected_model})
              .then((res) => {
                if(res.success) {
                  this.new_text = res.data;
                  this.showSaveButton = true;
                }else{
                  this.new_text = null;
                  this.old_text = null;
                  Vue.$toast.open({
                    message: res.message,
                    type: 'error',
                    duration: 10000,
                    position: 'bottom'
                  })
                }
                this.rewrite_working = false;
              })
              .catch((err) => {
                console.log(err);
                if (err.response.data.errors) {
                  this.$refs.createForm.setErrors(err.response.data.errors)
                }
                this.loadingContent = false;
                this.rewrite_working = false;
              })
        }
      },
      copyContent() {
        let editor = this.$refs.te.editor;
        editor.selection.select(editor.getBody());
        editor.execCommand('Copy');
        editor.selection.collapse();
        /* let container = document.createElement('div');
        container.innerHTML = editor.getContent();
        let textContent = container.textContent;
        navigator.clipboard.writeText(textContent); */
        Vue.$toast.open({
          message: 'Copied',
          type: 'success',
          duration: 5000,
          position: 'bottom'
        })
      },
      saveContent(){
        this.savingContent = true;
        this.$store
            .dispatch('content/saveContent', {
              id: this.content_id,
              title: this.activeTitle,
              subtitles: this.subTitles,
              type:this.type,
              topic:this.topic,
              name:this.projectName,
              content:this.final_content,
              tone:this.selected_tone,
              freeform_prompt: this.freeform_prompt,
              writing_style:this.selected_style,
              word_count: this.word_count,
              include_introduction: this.ebook_intro,
              include_conclusion: this.ebook_conclusion,
              model:this.selected_model
            })
            .then((res) => {
              if(!this.content_id){
                this.$router.push({ path: '/agency/content/'+res.id+'/view' });
                this.content_id = res.id;
              }
              Vue.$toast.open({
                message: 'Content Saved',
                type: 'success',
                duration: 5000,
                position: 'bottom'
              })
              this.savingContent = false;
              this.contentChanged = false;
            })
            .catch((err) => {
              if (err.response.data.errors) {
                this.$refs.createForm.setErrors(err.response.data.errors)
              }
              this.savingContent = false
            })
      },
      generateContent() {
        this.loadingContent = true;
        this.$store
          .dispatch('content/generateContent', {
            title: this.activeTitle,
            subtitles: this.subTitles,
            tone: this.selected_tone,
            type:this.type,
            composition_type:this.composition_type,
            word_count:this.word_count,
            freeform_prompt:this.freeform_prompt,
            writing_style:this.selected_style,
            ebook_intro:this.ebook_intro,
            ebook_conclusion:this.ebook_conclusion,
            model:this.selected_model

          })
          .then((res) => {
              this.loadingContent = false;
              if(res.success) {
                this.final_content = res.data;
                this.showSaveButton = true;
              }else{
                Vue.$toast.open({
                  message: res.message,
                  type: 'error',
                  duration: 5000,
                  position: 'bottom'
                })
              }
          })
          .catch((err) => {
            console.log(err);
              if (err.response.data.errors) {
                this.$refs.createForm.setErrors(err.response.data.errors)
              }
              this.loadingContent = false
          })
      },
      refreshTitle(title) {
        this.titleUpdating = title;
          this.$store
          .dispatch('content/refreshTitle', {title: title, topic:this.topic, tone: this.selected_tone, type:this.type, model:this.selected_model})
          .then((res) => {
            console.log(res);
            if(res.success) {
              this.titleUpdating = null;
              this.activeTitle = res.data;
            }else{
              this.titleUpdating = null;
              Vue.$toast.open({
                message: res.message,
                type: 'error',
                duration: 5000,
                position: 'bottom'
              })
            }
          })
          .catch((err) => {
            console.log(err);
              if (err.response.data.errors) {
                console.log(err);
                this.$refs.createForm.setErrors(err.response.data.errors)
              }
          })
      },
      refreshSubtitle(subtitle) {
        this.updateId = subtitle;
        subtitle.topic = this.topic;
        subtitle.tone = this.selected_tone;
        subtitle.type = this.type;
        subtitle.model = this.selected_model;
        subtitle.existing_subtitles = this.subTitles.filter(subs => subs.title !== subtitle.title).map(sub => sub.title);
        // this.loadingCreate = true
          this.$store
          .dispatch('content/refreshSubTitle', subtitle)
          .then((res) => {
              //this.getData();
            if(res.success) {
              this.updateId = null;
            }else{
              Vue.$toast.open({
                message: res.message,
                type: 'error',
                duration: 5000,
                position: 'bottom'
              })
            }
          })
          .catch((err) => {
              console.log(err);
              if (false && err.response.data.errors) {
              this.$refs.createForm.setErrors(err.response.data.errors)
              }
          })
      },
      generateTitles() {
          this.loadingCreate = true;
          this.$store
          .dispatch('content/generateTitles', {projectName: this.projectName, type: this.type, topic: this.topic, tone: this.selected_tone, model:this.selected_model})
          .then((res) => {
              console.log(res);
              if(res.success) {
                this.activeTitle = this.titles[0].title;
              }else{
                Vue.$toast.open({
                  message: res.message,
                  type: 'error',
                  duration: 5000,
                  position: 'bottom'
                });
              }
              this.loadingCreate = false;
              // this.$router.push({ name: 'admin.plans.index' })
          })
          .catch((err) => {
              if (err.response.data.errors) {
              this.$refs.createForm.setErrors(err.response.data.errors)
              }
              this.loadingCreate = false
          })
      },
      setTEOptions(){
        //this.$refs.te.editor.execCommand('LineHeight', false, '1.5');
      },
      getData() {
        if(this.content_id) {
          this.$store.dispatch('content/getData', this.content_id)
              .then((res) => {
                this.title = res?.title;
                this.activeTitle = this.title;
                this.projectName = res?.name;
                this.type = res?.type ?? null;
                this.topic = res?.topic;
                this.subTitles = res?.subheadings;
                this.final_content = res?.content;
                this.freeform_prompt = res?.freeform_prompt;
                this.showSaveButton = true;
                this.completelyLoaded = true;
                this.selected_tone = res?.tone;
                this.selected_style =  res?.writing_style ?? 'Default';
                this.num_of_subtitles = this.subTitles.length;
                this.word_count = res?.word_count;
                this.ebook_intro = res?.include_introduction;
                this.ebook_conclusion = res?.include_conclusion;
                this.selected_model = res?.api_model ?? 'gpt-3.5-turbo';
              });
        }else{
          this.completelyLoaded = true;
        }
      }
    },
  }
  </script>
<style lang="scss" scoped>
  .content {
    margin-top: 28px;
    h2{
      margin-bottom: -10px;
    }
  }
  .subtitle-box {
    align-items: center;
    margin-top: 12px;
  }
  .create-button {
    text-align: right;
  }
  .dropbox {
    margin-top: 20px;
  }
  .checkbox {
    width: 20px;
    height: 20px;
  }
  .refresh-icon {
    cursor: pointer;
    margin-left: 5px;
  }
  .mt-16 {
    margin-top: 16px;
  }
  .content-region {
    width: 100%;
    /*border: 1px solid #BDBDBD;*/
  }
  .title {
    font-weight: bold;
    font-size: 20px;
  }
  .subtitle {
    font-weight: bold;
    font-size: 16px;
  }
  .loading-text {
    margin-top: 46px;
    font-size: 18px;
  }
  .start-over-button {
    margin-right: 20px;
  }
  .handle{
    cursor: pointer;
  }
</style>
<style>
.vs__search, .vs__search:focus{
  font-size: 14px;
}
</style>
